import { Provider } from 'react-redux'
import Router from './routes'
import { store, ThriveApplication } from '@thriveglobal/thrive-web-core'

const loadi18n = (lang: string) =>
    fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)
export default function Root(props) {
    return (
        <ThriveApplication
            messagesLoaders={[loadi18n]}
            apolloClientOptions={{
                cacheOptions: {},
                name: 'thrive-admin-journeys'
            }}
            // sentryDsn={process.env.SENTRY_DSN}
        >
            <Provider store={store}>
                <section>
                    <Router />
                </section>
            </Provider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
